import create from 'zustand';

import createBuildingMeasurementsSlice, {
  BuildingMeasurementsState,
} from './slices/measurements/buildingMeasurementsSlice';
import createEmptyMeasurementsSlice, { EmptyMeasurementsState } from './slices/measurements/emptyMeasurementsSlice';
import createParcelMeasurementsSlice, { ParcelMeasurementsState } from './slices/measurements/parcelMeasurementsSlice';
import createPatrimonyMeasurementsSlice, {
  PatrimonyMeasurementsState,
} from './slices/measurements/patrimonyMeasurementsSlice';
import createPipeMeasurementsSlice, { PipeMeasurementsState } from './slices/measurements/pipeMeasurementsSlice';
import createSewerMeasurementsSlice, { SewerMeasurementsState } from './slices/measurements/sewerMeasurementsSlice';
import createTerrainMeasurementsSlice, {
  TerrainMeasurementsState,
} from './slices/measurements/terrainMeasurementsSlice';
import createMeasurementsSlice, { MeasurementsState } from './slices/measurementsSlice';

export type GlobalState = MeasurementsState &
  TerrainMeasurementsState &
  BuildingMeasurementsState &
  PatrimonyMeasurementsState &
  PipeMeasurementsState &
  SewerMeasurementsState &
  EmptyMeasurementsState &
  ParcelMeasurementsState;

const useStore = create<GlobalState>((...params) => ({
  ...createMeasurementsSlice(...params),
  ...createTerrainMeasurementsSlice(...params),
  ...createBuildingMeasurementsSlice(...params),
  ...createPatrimonyMeasurementsSlice(...params),
  ...createPipeMeasurementsSlice(...params),
  ...createSewerMeasurementsSlice(...params),
  ...createEmptyMeasurementsSlice(...params),
  ...createParcelMeasurementsSlice(...params),
}));

export default useStore;
