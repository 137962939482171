import { createContext } from 'react';
import { LocalSettings, MapSettings } from './settings.model';

interface SettingsContextType {
  appSettings: LocalSettings;
  mapSettings: MapSettings;
  setSettings: (settings: LocalSettings, persist: boolean) => void;
  setMapSettings: (mapSettings: Partial<MapSettings>) => void;
}

export const SettingsContext = createContext<SettingsContextType | undefined>(undefined);
