import {
  Checkbox,
  DisplaySettingsIcon,
  Divider,
  FormControl,
  FormControlLabel,
  ImportExportIcon,
  Input,
  InputLabel,
  ListItem,
  ListItemText,
  MenuBookIcon,
  MenuItem,
  ProfileIcon,
  Select,
  SelectChangeEvent,
  Switch,
  TableChartIcon,
  useCurrentUser,
} from '@sgde/core';
import { ChangeEvent } from 'react';
import DxfImport from '../../components/DxfImport/DxfImport';
import ProfileSettings from '../../components/ProfileSettings/ProfileSettings';
import { useAppSettings } from '../../models/settings/useAppSettings';
import { useStreetViewYears } from '../../store/slices/streeViewApi';
import ExportReports from '../ExportReports/ExportReports';
import PublicPage from '../Modal/PublicPage/PublicPage';
import Preferences from '../Preferences/Preferences';
import { MenuItemWithDialogAction } from './MenuItemWithDialogAction';
import { PublicMenuItems } from './PublicMenuItems';

export const MenuItems = () => {
  const { data: user, isSuccess } = useCurrentUser();
  const { appSettings, setSettings } = useAppSettings();

  const { data: streetViewYears } = useStreetViewYears(undefined, {
    skip: !isSuccess,
  });

  const softDeleted = user && user.softDeleted;
  const isSuperAdmin = user?.roles?.some(role => role.name === 'SuperAdmin');
  const isAdmin = isSuperAdmin || user?.roles?.some(role => role.name === 'Admin');

  const handleShowStreetViewChange = (event: ChangeEvent<HTMLInputElement>) => {
    const showStreetView = event.target.checked;
    const newSettings = { ...appSettings, showStreetView };
    setSettings(newSettings, true);
  };

  const handleYearsChange = (event: SelectChangeEvent<number[]>) => {
    const options = event.target.value as number[];
    const newSettings = { ...appSettings, streetViewYears: options };
    setSettings(newSettings, true);
  };

  return (
    <>
      {!!user && (
        <MenuItemWithDialogAction
          title="Setari profil"
          icon={<ProfileIcon fontSize="small" />}
          mainContent={<ProfileSettings />}
          width="lg"
        >
          Profil
        </MenuItemWithDialogAction>
      )}
      {!softDeleted && (
        <MenuItemWithDialogAction
          title="Export Rapoarte"
          icon={<TableChartIcon fontSize="small" />}
          mainContent={<ExportReports />}
          fullScreen={true}
        >
          Rapoarte
        </MenuItemWithDialogAction>
      )}
      {isAdmin && (
        <MenuItemWithDialogAction
          title="Import DXF"
          icon={<ImportExportIcon fontSize="small" />}
          mainContent={<DxfImport />}
          width="md"
        />
      )}
      {!softDeleted && (
        <MenuItemWithDialogAction
          title="Pagina public"
          icon={<MenuBookIcon fontSize="small" />}
          mainContent={<PublicPage />}
          width="lg"
        />
      )}
      {!softDeleted && (
        <MenuItemWithDialogAction
          title="Preferinte"
          icon={<DisplaySettingsIcon fontSize="small" />}
          mainContent={<Preferences />}
          width="lg"
        />
      )}
      {!softDeleted && <Divider />}
      {!softDeleted && <ListItem> Preferinte rapide:</ListItem>}
      {!softDeleted && (
        <ListItem>
          <FormControlLabel
            control={
              <Switch
                checked={!!appSettings.showStreetView}
                onChange={handleShowStreetViewChange}
                name="showStreetView"
                color="primary"
              />
            }
            label="Street View"
          />
        </ListItem>
      )}
      {!softDeleted && (
        <ListItem key={'StreetViewYears'}>
          <FormControl style={{ width: '100%' }}>
            <InputLabel>Afisare street view</InputLabel>
            <Select
              fullWidth
              multiple
              value={appSettings.streetViewYears ?? []}
              onChange={handleYearsChange}
              renderValue={selected => selected.join(', ')}
              input={<Input />}
            >
              {streetViewYears?.map(year => (
                <MenuItem key={year} value={year}>
                  <Checkbox checked={appSettings.streetViewYears?.indexOf(year) > -1} />
                  <ListItemText primary={year} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ListItem>
      )}
      <Divider />
      <PublicMenuItems />
    </>
  );
};
