import { Box, Link, Stack, ThemeMode, useTheme } from '@sgde/core';
import { useAppSettings } from '../../models/settings/useAppSettings';

export const Logo = () => {
  const { appSettings } = useAppSettings();
  const theme = useTheme();
  const gisLogoPath =
    theme.palette.mode === ThemeMode.Dark ? '/gis-logo/GIS-logo-dark.png' : '/gis-logo/GIS-logo-light.png';

  if (appSettings?.isPublic) {
    return (
      <Link component={'a'} href="https://sgde.ro" target="_blank" rel="noreferrer">
        <Box component="img" alt="SGDE Logo" width={80} src="/logo.png" />
      </Link>
    );
  } else {
    return (
      <Stack>
        <Box component="img" alt="GIS Logo" width={100} src={gisLogoPath} />
      </Stack>
    );
  }
};
