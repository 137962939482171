import { useCurrentUser } from '@sgde/core';
import { useCallback, useEffect, useState } from 'react';
import { getPublicPageName } from '../../store/slices/publicPageApi';
import { useSettings, useUpdateSettings } from '../../store/slices/settingsApi';
import { LocalSettings, MapSettings } from './settings.model';
import { SettingsContext } from './SettingsContext';

const defaultSettings: LocalSettings = {
  isPublic: false,
  isLoading: true,
  streetViewYears: [],
  reportTypes: [],
  backgroundType: 'ortofotoplan',
};

const defaultMapSettings: MapSettings = {
  mapBounds: [[]],
  extent: [],
  mapZoom: 16,
  editing: false,
  mapTypes: [],
};

export const SettingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isSuccess } = useCurrentUser();
  const [appSettings, setAppSettings] = useState<LocalSettings>(defaultSettings);
  const [contextMapSettings, setContextMapSettings] = useState<MapSettings>(defaultMapSettings);
  const { data: apiSettings, isLoading: apiSettingsLoading } = useSettings(undefined, {
    skip: !!getPublicPageName() || !isSuccess,
  });
  const [setApiSettings] = useUpdateSettings();

  useEffect(() => {
    const localSettings = Object.assign({ isLoading: apiSettingsLoading }, apiSettings);
    if (apiSettings) {
      setAppSettings(localSettings);
    }
  }, [apiSettings, apiSettingsLoading]);

  const setSettings = useCallback(
    (settings: LocalSettings, persist: boolean) => {
      setAppSettings(settings);
      if (persist) {
        setApiSettings(settings);
      }
    },
    [setApiSettings]
  );

  const setMapSettings = useCallback((mapSettings: Partial<MapSettings>) => {
    setContextMapSettings(prevState => {
      let mapBounds = mapSettings.mapBounds || prevState.mapBounds;

      if (mapSettings.extent) {
        const { extent } = mapSettings;

        const southWest = [extent[0], extent[1]];
        const northEast = [extent[2], extent[3]];
        const southEast = [northEast[0], southWest[1]];
        const northWest = [southWest[0], northEast[1]];
        mapBounds = [southWest, southEast, northEast, northWest];
      }

      return {
        ...prevState,
        ...mapSettings,
        mapBounds,
      };
    });
  }, []);

  return (
    <SettingsContext.Provider value={{ appSettings, setSettings, mapSettings: contextMapSettings, setMapSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};
