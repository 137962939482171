import '@fontsource-variable/overpass';
import { CoreProvider } from '@sgde/core';
import 'long-press-event/dist/long-press-event.min';
import 'ol-contextmenu/ol-contextmenu.css';
import 'ol/ol.css';
import ReactDOM from 'react-dom/client';
import { Pages } from './components/Pages/Pages';
import './index.css';
import { SettingsProvider } from './models/settings/SettingsProvider';
import { DataProvider } from './primitives/Data/DataContext';
import { ModalProvider } from './primitives/Modal/ModalContext';
import { ProgressProvider } from './primitives/Progress/Progress';
import * as serviceWorker from './serviceWorker';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <CoreProvider>
    <SettingsProvider>
      <ProgressProvider>
        <DataProvider>
          <ModalProvider>
            <Pages />
          </ModalProvider>
        </DataProvider>
      </ProgressProvider>
    </SettingsProvider>
  </CoreProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
