import { SkipToken, skipToken } from '@reduxjs/toolkit/query';
import { IconButton, SearchIcon } from '@sgde/core';
import { useContext, useEffect, useState } from 'react';
import SearchReport from '../../components/SearchReport/SearchReport';
import { Report } from '../../models/report.model';
import { useAppSettings } from '../../models/settings/useAppSettings';
import { useMeasurementsById } from '../../store/slices/measurementsApi';
import { useReportFieldsById } from '../../store/slices/reportsApi';
import { ModalContext } from '../Modal/ModalContext';

const SearchableReportTypes = [1, 2, 4, 5, 6];

const MenuSearch = () => {
  const { dispatch: modal } = useContext(ModalContext);
  const { appSettings, setSettings, setMapSettings } = useAppSettings();

  const [report, setReport] = useState<Report>();
  const [measurementId, setMeasurementId] = useState<string | SkipToken>(skipToken);
  const { data: reportFields } = useReportFieldsById(report?.id ?? skipToken);
  const { data: measurements } = useMeasurementsById(measurementId);

  useEffect(() => {
    if (reportFields) {
      const reportFieldDefinition = reportFields.find(rf => rf.reportFieldDefinitionId === 35);
      if (reportFieldDefinition?.value) setMeasurementId(reportFieldDefinition.value);
    }
  }, [reportFields, setMeasurementId]);

  useEffect(() => {
    if (!measurements) return;

    const areaMeasurement = { ...measurements[0] };

    if (!report) return;

    areaMeasurement.reportTypeId = report.reportTypeId;
    areaMeasurement.parentReportId = report.id;

    const newSettings = { ...appSettings, currentLocationSetting: areaMeasurement };

    setMapSettings({ mapZoom: 14 });
    setSettings({ ...newSettings }, false);
    if (areaMeasurement.coordinatesArray && areaMeasurement.coordinatesArray.length > 0) {
      modal?.({ type: 'close' });
    }
  }, [reportFields, measurements, modal, setSettings, setMapSettings]);

  const onSearchTerrainClick = () => {
    modal?.({
      type: 'open',
      title: 'Cautare Imobil',
      width: 'lg',
      mainContent: (
        <div>
          <SearchReport
            reportTypes={SearchableReportTypes}
            onSelectedReport={(selected: Report) => {
              selected.id && setReport({ ...selected });
            }}
            hideCheckboxes={true}
            hideNamePropertyInColumnList={true}
            showNavigateIcon={true}
          ></SearchReport>
        </div>
      ),
    });
  };

  return (
    <IconButton onClick={onSearchTerrainClick} edge="end" size="medium">
      <SearchIcon />
    </IconButton>
  );
};

export default MenuSearch;
