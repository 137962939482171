import { InteractionType } from '@azure/msal-browser';
import { AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import { Box } from '@sgde/core';
import { useEffect } from 'react';
import { Home } from '../../components/Home/Home';
import { Popup } from '../../components/Popup/Popup';
import { useAppSettings } from '../../models/settings/useAppSettings';
import { AppHeader } from '../../primitives/Menu/AppHeader';
import OpenLayersMapView from '../../primitives/OpenLayers/OpenLayersMapView';
import { getPublicPageName, usePublicPageSettingsByName } from '../../store/slices/publicPageApi';

const usePublicSetup = ({ publicPageName }: { publicPageName: string }) => {
  const { data: publicSettings, isLoading } = usePublicPageSettingsByName(publicPageName);
  const { setSettings } = useAppSettings();

  useEffect(() => {
    setSettings(
      {
        publicPageName: publicSettings?.pageLink,
        publicPageTitle: publicSettings?.pageTitle,
        publicPageDefaultZoom: publicSettings?.pageDefaultZoom,
        reportTypes: publicSettings?.mapSettings.reportTypes ? publicSettings?.mapSettings.reportTypes : [],
        mapProvider: publicSettings?.mapSettings.mapProvider,
        mapType: publicSettings?.mapSettings.mapType,
        backgroundType: publicSettings?.mapSettings.backgroundType
          ? publicSettings?.mapSettings.backgroundType
          : 'ortofotoplan',
        showGroundOverlays: publicSettings?.mapSettings.showGroundOverlays,
        showStreetView: publicSettings?.mapSettings.showStreetView,
        streetViewYears: publicSettings?.mapSettings.streetViewYears ? publicSettings?.mapSettings.streetViewYears : [],
        showCustomStyles: publicSettings?.mapSettings.showCustomStyles,
        lastLocation: publicSettings?.mapSettings.lastLocation,

        isPublic: true,
        isLoading,
      },
      false
    );
  }, [publicSettings, isLoading, setSettings]);
};

const PrivatePage = () => {
  useMsalAuthentication(InteractionType.Redirect);

  return (
    <Box className="App">
      <Popup />
      <AppHeader />
      <AuthenticatedTemplate>
        <Home />
      </AuthenticatedTemplate>
    </Box>
  );
};

const PublicPage = (props: { publicPageName: string }) => {
  usePublicSetup(props);

  return (
    <Box className="App">
      <Popup />
      <AppHeader />
      <OpenLayersMapView />
    </Box>
  );
};

export const Pages = () => {
  const publicPageName = getPublicPageName();

  return publicPageName ? <PublicPage publicPageName={publicPageName} /> : <PrivatePage />;
};
