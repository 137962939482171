import { Header, Stack, Typography, useCurrentUser, useHeader } from '@sgde/core';
import { useEffect } from 'react';
import { useAppSettings } from '../../models/settings/useAppSettings';
import { Logo } from '../Logo/Logo';
import { useStyles } from './Menu.styles';
import { MenuItems } from './MenuItems';
import MenuSearch from './MenuSearch';
import { PublicMenuItems } from './PublicMenuItems';

export const AppHeader = () => {
  const { appSettings } = useAppSettings();
  const { data: user } = useCurrentUser();
  const { classes } = useStyles();
  const { setMenuItems } = useHeader();

  useEffect(() => {
    const menuItems = [
      <Stack direction="row" alignItems="center" justifyContent={'center'} sx={{ flex: 1 }}>
        <Typography variant="h6" className="menu-title">
          {appSettings.publicPageTitle}
        </Typography>
      </Stack>,
    ];

    // add search to menu just for logged in users or public pages
    if ((user && !user.softDeleted) || appSettings.isPublic) {
      menuItems.push(<MenuSearch />);
    }

    setMenuItems(menuItems);
  }, [setMenuItems, appSettings, user]);

  return (
    <Header
      logo={<Logo />}
      elevation={0}
      className={classes.header}
      profileMenuItems={appSettings.isPublic ? <PublicMenuItems /> : <MenuItems />}
      hideLogin={appSettings.isPublic}
    />
  );
};
