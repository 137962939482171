import { baseApi } from '@sgde/core';
import Settings from '../../models/settings/settings.model';

const settingsApi = baseApi.enhanceEndpoints({ addTagTypes: ['Settings'] }).injectEndpoints({
  endpoints: build => ({
    getSettings: build.query<Settings, void>({
      query: () => ({
        url: '/users/settings',
        method: 'GET',
      }),
    }),
    updateSettings: build.mutation<Settings, Settings>({
      query: data => ({
        url: '/users/settings',
        method: 'PUT',
        body: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          settingsApi.util.updateQueryData('getSettings', undefined, draft => {
            Object.assign(draft, arg);
          })
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const { useGetSettingsQuery: useSettings, useUpdateSettingsMutation: useUpdateSettings } = settingsApi;
